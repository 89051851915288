<template>
  <b-row>
    <b-col>
      <b-card class="card-tracking" v-if="loaded">
        <div class="d-flex justify-content-between p-1">
          <h3 class="pl-1 pt-1">{{$t('tracking.trackingURL')}}</h3>
          <b-button class="button-max-views" @click="createTrackingLink">{{ $t('tracking.trackingAvaibles') }}: {{ links_avaible }}</b-button>
        </div>
        <div class="p-1 w-100 d-flex justify-content-between border-top">
          <b-button :disabled="!is_free && links_avaible === 0" variant="blue-button" class="blue-button" @click="createTrackingLink">
            <feather-icon icon="PlusIcon" class="mr-04"></feather-icon>
            <span>{{$t('tracking.addLink')}}</span>
          </b-button>
          <div>
            <b-button class="ml-1" variant="flat-secondary" @click="updateService()">
              <feather-icon icon="RepeatIcon" class="mr-02"></feather-icon>
              {{$t('tracking.update')}}</b-button>
            <b-dropdown :text="`${$t('tracking.showing')} ${active_links ? $t('tracking.activedLink') : $t('tracking.archivedLink')}`" variant="flat-secondary" class="ml-1">
              <b-dropdown-item class="d-flex align-items-center" @click="getLinks(); active_links = true">
                <b-icon icon="circle-fill" class="circle-icon-active-links text-success"></b-icon>
                <span>{{$t('tracking.activedLink')}}</span>
              </b-dropdown-item>
              <b-dropdown-item class="d-flex align-items-center" @click="getLinksArchived(); active_links = false">
                <b-icon icon="circle" class="circle-icon-active-links"></b-icon>
                <span class="">{{$t('tracking.archivedLink')}}</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-button class="ml-1" variant="flat-secondary" @click="exportLink()"><b-icon icon="box-arrow-in-down" class="icon-export"></b-icon>{{$t('tracking.export')}}</b-button>
          </div>
        </div>
        
        <div class="scrollable-table" v-if="tracking.length > 0">
          <b-table
            :fields="$t('tracking.table_tracking')"
            :items="tracking"
            id="table-tracking"
            head-variant="light"
          > 
            <template #cell(id)="data">
              {{data.index + 1}}
            </template>
            <template #cell(name)="data">
              <div class="d-flex justify-content-start">
                {{(data.item.name && data.item.name.length > 0) ? data.item.name : '-'}}
              </div>
            </template>
            <template #cell(view_detail)="data">
              <feather-icon icon="EyeIcon" class="cursor-pointer" :id="`tooltip_view_${data.index}`" @click="$router.push({name: 'detail_tracking_links', params: {tracking_uuid: data.item.uuid}})"></feather-icon>
              <b-tooltip :target="`tooltip_view_${data.index}`">{{$t('tracking.viewLink')}}</b-tooltip>
            </template>
            <template #cell(original_link)="data">
              <div class="position-relative">
                <span class="container-destination-link">{{data.item.original_link.substr(0, 30)}} {{(data.item.original_link.length > 30) ? '...' : ''}}</span>
                <feather-icon icon="CopyIcon" class="copy-icon-table" size="23" :id="`tooltip_name_${data.index}`" @click="copyAction(data.item.original_link)"></feather-icon>
                <b-tooltip :target="`tooltip_name_${data.index}`">{{$t('tracking.copyLink')}}</b-tooltip>
              </div>
            </template>
            <template #cell(shorten_link)="data">
              <div class="position-relative">
                <span class="container-destination-link">{{data.item.shorten_link}}</span>
                <feather-icon icon="CopyIcon" class="copy-icon-table" size="23" :id="`tooltip_short_${data.index}`" @click="copyAction(data.item.shorten_link)"></feather-icon>
                <b-tooltip :target="`tooltip_short_${data.index}`">{{$t('tracking.copyShort')}}</b-tooltip>
              </div>
            </template>
            <template #cell(total_clicks)="data">
              <div class="position-relative">
                <b-spinner :ref="data.item.uuid" :id="`tooltip_update_clicks_${data.item.uuid}`"></b-spinner>
                <b-tooltip :target="`tooltip_update_clicks_${data.item.uuid}`">{{ $t('tracking.updateTotalLinks') }}</b-tooltip>
                <span :ref="`clicks-${data.item.uuid}`" class="d-none">{{data.item.total_clicks}}</span>
              </div>
            </template>
            <template #cell(created_at)="data">
              {{getDatev2(new Date(data.item.created_at))}}
            </template>
            <template #cell(updated_at)="data">
              {{getDatev2(new Date(data.item.updated_at))}}
            </template>
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret dropleft>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon"></feather-icon>
                </template>
                <b-dropdown-item @click="editAction(data.item)">
                  <feather-icon icon="EditIcon" class="mr-02"></feather-icon>
                  {{$t('tracking.editLink')}}
                </b-dropdown-item>
                <b-dropdown-item @click="archiveLink(data.item.uuid)">
                  <feather-icon icon="ArchiveIcon" class="mr-02" v-if="active_links"></feather-icon>
                  <b-icon v-else icon="arrow-up-square" class="mr-02 arrow-up-square-icon"></b-icon>
                  {{!active_links ? $t('tracking.unarchiving') : $t('tracking.archiving')}} Link
                </b-dropdown-item>
                <b-dropdown-item @click="deleteLink(data.item.uuid)">
                  <feather-icon icon="TrashIcon" class="mr-02"></feather-icon>
                  {{$t('tracking.deleteLink')}}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
        <div v-else class="p-1 d-flex justify-content-center">
         <div class="col-12 col-lg-6">
          <no-Results 
            :title="$t('dashboard.noLinks')"
            :subtitle="$t('dashboard.noLinksSubtitle')"
            :text_button="$t('tracking.addLink')"
            :icon="'plus'"
            :btn_action="addLink"
          />
         </div>
        </div>
      </b-card>
    </b-col>
    <modal-add-link 
      :link="link_to_edit" 
      :open_modal="open_modal" 
      :key="update_modal" 
      @add_data="addInfoTable" 
      @close_modal="closeModal"
      @update_data="updateInfoTable"
    />

    <modal-membership-section
      :text_selected_dad="text_selected" 
      :open_modal="modal_membership"
      @close_modal="modal_membership = false"
      :key="update_modal_membership"
    ></modal-membership-section>
  </b-row>
</template>

<script>
import {
  BIcon,
  BRow,
  BCol,
  BTable,
  BCard,
  BDropdown,
  BDropdownItem,
  BButton,
  BTooltip,
  BSpinner
} from 'bootstrap-vue';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import modalAddLink from '@/views/pages/tracking-links/modalAddLink.vue';
import tracking_service from '@/services/tracking_links';
import { getDatev2 } from "@/libs/utils/times";
import { getUserData } from '@/libs/utils/user';

export default {
  name: 'trackingLinks',
  components: {
    BIcon,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    BRow,
    BCol,
    BTable,
    BCard,
    BDropdown,
    BDropdownItem,
    BButton,
    BTooltip,
    modalAddLink,
    BSpinner,
    ModalMembershipSection: () => import('@/views/components/modal/ModalMembershipSection.vue')
  },
  data() {
    return {
      getDatev2,
      tracking: [],
      open_modal: false,
      update_modal: false,
      link_to_edit: {},
      active_links: true,
      limit_links: 0,
      loaded: false,
      links_avaible: 0,
      text_selected: 'tracking_links',
      modal_membership: false,
      update_modal_membership: 0,
    }
  },
  computed: {
    is_free() {
      return getUserData().current_plan.name === 'Free'
    }
  },
  created() {
    const current_plan = getUserData().current_plan
    this.limit_links = current_plan.limit_links;

    if (Object.keys(this.$route.query).length === 0) {
      window.history.pushState({}, null, `/tracking?status=active`);
      this.getLinks();
    } else {
      window.history.pushState({}, null, `/tracking?status=${this.$route.query.status}`);
      if  (this.$route.query.status === 'active') this.getLinks();
      else {
        this.getLinksArchived();
        this.active_links = false;
      }
    }
    this.getTotalLinksCreated()

    this.$root.$on('updated_clicks', (data) => {
      this.updateClicks(data.body);
    })
  },
  methods: {
    updateClicks(data) {
      data.forEach((item) => {
        this.$refs[item.shorten_uuid].classList.add('d-none');

        const tracking_link = this.tracking.find((link) => link.uuid === item.shorten_uuid);
        tracking_link.total_clicks = item.total_clicks;
        this.$refs[`clicks-${item.shorten_uuid}`].classList.remove('d-none');
      });
    },
    createTrackingLink() {
      if (this.links_avaible > 0) this.addLink()
      else if (this.links_avaible === 0 && this.is_free) {
        this.modal_membership = true;
        this.update_modal_membership += 1; 
      }
    },
    getTotalLinksCreated() {
      tracking_service.getShortLinksCreated(true).then(response => {        
        this.links_avaible = this.limit_links - response.total_links_created
      })
    },
    updateService() {
      this.active_links ? this.getLinks() : this.getLinksArchived();
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position: 'top-right',
          props: {
            title: this.$t('tracking.updateInformation'),
            icon: "BellIcon",
            text: this.$t('tracking.updateLink'),
            variant: 'success'
          }
        })
      })
    },
    copyAction(link) {
      this.$copyText(link).then(() => {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('campaigns.copied'),
              icon: "CopyIcon",
              text: this.$t('campaigns.clipboard'),
              variant: 'success'
            }
          })
        })
      });
    },
    closeModal() {
      this.open_modal = false; 
      this.update_modal = !this.update_modal
    },
    addInfoTable(data) {
      this.closeModal();
      this.tracking.unshift(data);
      this.links_avaible -= 1
    },
    updateInfoTable(data) {
      this.closeModal();
      const new_list = [];
      this.tracking.map((link) => {
        if (data.uuid === link.uuid) link = data;
        new_list.push(link);
      });
      this.tracking = new_list;
    },
    getLinks() {
      tracking_service.getShortLinks().then((response) => {
        this.tracking = response.results;
        this.loaded = true;
      });
    },
    getLinksArchived() {
      tracking_service.getShortLinksArchived().then((response) => {
        this.tracking = response.results;
        this.loaded = true;
      });
    },
    archiveLink(link_uuid) {
      this.$swal({
        title: `${this.$t('tracking.sure?')} ${this.active_links ? this.$t('tracking.archiving') : this.$t('tracking.unarchiving')} ${this.$t('tracking.thisLink')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t('tracking.btnYes')} ${this.active_links ? this.$t('tracking.archiving') : this.$t('tracking.unarchiving')}`,
        cancelButtonText: 'No',
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          tracking_service.toggleStatusShortLinks(link_uuid).then(() => {
            this.tracking = this.tracking.filter((link) => link.uuid !== link_uuid);
          });
        }
      });
    },
    deleteLink(link_uuid) {
      this.$swal({
        title: this.$t('tracking.delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('tracking.deleteYes'),
        cancelButtonText: 'No',
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          tracking_service.deleteShortLinks(link_uuid).then(() => {
            this.tracking = this.tracking.filter((link) => link.uuid !== link_uuid);
            this.links_avaible += 1
          });
        }
      });
    },
    downloadCsv(rows, name) {
      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = name;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    exportLink() {
      const rows = [[this.$t('tracking.name'), this.$t('tracking.destinationUrl'), this.$t('tracking.shortUrl'), 'Click', this.$t('tracking.createAt'), this.$t('tracking.updatedAt')]];
      this.tracking.forEach(data => {
        rows.push([
          data.name,
          data.original_link,
          data.shorten_link,
          data.total_clicks,
          data.created_at,
          data.updated_at,
        ])
      })
      this.downloadCsv(rows, "tracking_links.csv")
    },
    editAction(link) {
      this.link_to_edit = {
        link: link.original_link,
        name: link.name,
        utm_source: link.utm_source,
        utm_medium: link.utm_medium,
        utm_campaign: link.utm_campaign,
        utm_content: link.utm_content,
        uuid: link.uuid
      };
      this.open_modal = true;
      this.update_modal = !this.update_modal;
    },
    addLink() {
      this.open_modal = true; 
      this.update_modal = !this.update_modal;
      this.link_to_edit = {};
    }
  },
  watch: {
    active_links(val) {
      window.history.pushState({}, null, `/tracking?status=${val ? 'active' : 'archived'}`);
    }
  }
}
</script>

<style>
.mr-04 {
  margin-right: 0.4em;
}
.icon-export {
  margin-right: 6px;
  padding: 1px 0px 1px 0px;
}
.blue-button {
  max-height: 35px !important;
  min-width: 140px;
  padding-left: 2px;
  padding-right: 2px;
}
.thead-light th {
  background-color: #FAFAFA !important;
  border-top: 1px #f2f2f2 solid;
}
#table-tracking {
  overflow: scroll !important;
}
#table-tracking td {
  padding: 18px;
  border-bottom: 1px solid #eff3f6;
}
#table-tracking-card .card-body {
  padding: 0;
}
#table-tracking th {
  padding: 18px;
}

</style>
<style scoped>
.button-go {
  margin-top: 10px;
  border-color: rgba(222,226,230,.7);
  background: transparent;
  color: #495057;
  font-weight: 400;
}
.button-go:hover {
  margin-top: 10px;
  border-color: rgba(222,226,230,.7);
  background: transparent;
  color: #212529 !important;
  font-weight: 400;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 14px 0px;
}
.subtitle-results {
  color: #495057;
}
.title-results {
  color: #212529;
  font-weight: bold;
  justify-content: center !important;
}
.border-card-results {
  border-color: rgba(222,226,230,.7) !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}
.container-destination-link {
  white-space: nowrap;
  overflow: hidden;
  color: #3483FA;
  padding-right: 30px;
}
.mr-02 {
  margin-right: 0.2em;
}
.card-tracking .card-body {
  padding: 0 !important;
}
.circle-icon-active-links {
  width: 10px;
  height: 10px;
  margin: 0 0.7em 3px 0;
}
.copy-icon-table {
  position: absolute;
  right: 0;
  background-color: #f2f2f2;
  color: #b5b5b5;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
}
.copy-icon-table:hover {
  background-color: #E0F1F6;
  color: #6DAECA;
}
.arrow-up-square-icon {
  width: 14px;
  height: 14px;
}
</style>